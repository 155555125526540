const articlesData = [
  {
    title: "Nuclear Waste Kids",
    url: "https://www.the-blueprint.ai/p/nuclear-waste-kids"
  },
  {
    title: "Your brand must be available physically, mentally and now for the AI algorithm",
    url: "https://www.thedrum.com/opinion/2025/04/01/your-brand-must-be-available-physically-mentally-and-now-the-ai-algorithm"
  },
  {
    title: "I really miss Steve Jobs",
    url: "https://www.the-blueprint.ai/p/i-really-miss-steve-jobs"
  },
  {
    title: "Web 4.0 - The Rise of the Agentic Web",
    url: "https://www.the-blueprint.ai/p/web-40-the-rise-of-the-agentic-web"
  },
  {
    title: "o1: The Next Step in Conversational AI",
    url: "https://www.the-blueprint.ai/p/o1-the-next-step-in-conversational"
  },
  {
    title: "Scared and Excited",
    url: "https://www.infosum.com/podcast/sean-betts-omg-scared-and-excited"
  },
  {
    title: "Beyond Chatbots: A Blueprint for LLMs",
    url: "https://www.the-blueprint.ai/p/beyond-chatbots-a-blueprint-for-llms"
  },
  {
    title: "AI For The Rest Of Us",
    url: "https://www.the-blueprint.ai/p/ai-for-the-rest-of-us"
  },
  {
    title: "The Search for (im)perfection",
    url: "https://newdigitalage.co/agencies/sean-betts-the-search-for-imperfection/"
  },
  {
    title: "O is for Omni, but A is for Agent",
    url: "https://www.the-blueprint.ai/p/o-is-for-omni-but-a-is-for-agent"
  },
  {
    title: "The Marketing Singularity is Nearer Than You Think",
    url: "https://newdigitalage.co/agencies/sean-betts-the-marketing-singularity-is-nearer-than-you-think/"
  },
  {
    title: "Leadership Lessons",
    url: "https://www.the-blueprint.ai/p/leadership-lessons"
  },
  {
    title: "Tech Pail Kids",
    url: "https://www.the-blueprint.ai/p/tech-pail-kids"
  },
  {
    title: "The Power of GPTs",
    url: "https://www.the-blueprint.ai/p/the-power-of-gpts"
  },
  {
    title: "OpenAI DevDay Special",
    url: "https://www.the-blueprint.ai/p/openai-devday-special"
  },
  {
    title: "AI Safety Summit: what marketers need to know",
    url: "https://www.thedrum.com/opinion/2023/11/07/ai-safety-summit-what-marketers-need-know"
  },
  {
    title: "Is Generative AI a Feature or a Platform?",
    url: "https://www.the-blueprint.ai/p/is-generative-ai-a-feature-or-a-platform"
  },
  {
    title: "The Balancing Act",
    url: "https://www.the-blueprint.ai/p/the-balancing-act"
  },
  {
    title: "Navigating the role of generative AI in digital marketing",
    url: "https://newdigitalage.co/agencies/sean-betts-the-generative-ai-hype-cycle/"
  }
];

export default articlesData;